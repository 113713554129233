<template>
  <credit-form
    :credit="credit"
  />
</template>
<script>
  export default {
    props: {
      credit: {
        type: Object,
        default: () => {},
      }
    },
    components: {
      CreditForm: () => import('@form/subscriptions/credit/CreditForm')
    }
  }
</script>
<style scoped>

</style>
